import React from "react";
import { replaceWithBrs } from "../../helpers/conversions";
import { getFont } from "../../helpers/params";

const Title = ({post, h}) => {
    const title = replaceWithBrs(post.title);

    const styles = {
        fontSize: `${post.titlefontsize}px`,
        color: `#${post.titlecolor}`,
        textAlign: post.titlejustify,
        fontFamily: getFont(post.titlefontfamily),
        display: title.includes('[none]') ? 'none' : 'block'
    };


    if(h === 'h2'){
        return <h2 style={styles} dangerouslySetInnerHTML={{__html: title }} />;
    }
    if(h === 'h3'){
        return <h3 style={styles} dangerouslySetInnerHTML={{__html: title }} />;
    }
    if(h === 'h4'){
        return <h4 style={styles} dangerouslySetInnerHTML={{__html: title }} />;
    }

    return <h1 style={styles} dangerouslySetInnerHTML={{__html: title }} />;
}

export default Title;