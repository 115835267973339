import React from 'react';
import { BrowserRouter, Navigate, Route, Routes as ReactRouterRoutes, useLocation } from "react-router-dom";

import Home from '../pages/Home';
import Form from '../pages/Form';
import Base from '../pages/Base';

const Routes = () => {
    return (
        <BrowserRouter>
            <ReactRouterRoutes>
                <Route exact path="/" element={<Home />} />

                <Route exact path="/:slice" element={<Base />} />
                <Route exact path="/:slice/:card" element={<Base />} />

                <Route exact path="/form" element={<Form />} />
            </ReactRouterRoutes>
        </BrowserRouter>
    );
}

export default Routes;