import axios from "axios";
import React, { useState, useEffect } from "react";
import { getFromBetween } from "../../helpers/helpers";

const Menu = ({items}) => {
    const pathname = window.location.pathname;
    const isHome = pathname === '/';

    return (
        <>
            {items.map((item, index) => (
                <li key={index} className={item.subitems.length > 0 ? 'submenu' : ''}>
                    {item.url === null &&
                        <a href={item.subitems.length === 0 ? `/${item.name}` : '#'}>{item.name}</a>
                    }
                    {item.url !== null && item.url !== 'home' &&
                        <a href={item.url}>{item.name}</a>
                    }
                    {item.url === 'home' && !isHome &&
                        <a href={`/`}>{item.name}</a>
                    }
                    {item.subitems.length > 0 &&
                        <ul>
                            {item.subitems.map((subitem, index) => (
                                <li key={index}><a href={`/${item.name}/${subitem.name}`}>{subitem.name}</a></li>
                            ))}
                        </ul>
                        
                    }
                </li>
            ))}
        </>
    );
};

const Header = () => {
    const [show, toggleShow] = useState(false);
    const [items, setItems] = useState([]);
    const [post, setPost] = useState(null);

    useEffect(() => {
        getPosts();
    }, []);

    const getPosts = () => {
        axios.get(`https://mediaroom.jbs.com.br/getposts.php`, {
            params: {
                bracket: 2,
                // slice: "Header",
                fullurl: '',
                nolinks: '',
                bullet: '',
                iframe: '',
            }
        }).then(res => {
            console.log('header', res);
            let post = res.data.posts.filter(post => post.slice === 'Header')[0];
            if(post === undefined) return;
            setPost(post);
            generateItems(post);
        });
    }

    const generateItems = post => {
        const pathname = window.location.pathname;
        const isHome = pathname === '/';
        let contents = post.contents;
        contents = contents.substring(
            contents.indexOf("<p>") + 3, 
            contents.lastIndexOf("</p>")
        );

        let params = getFromBetween.get(contents, '[', ']');
        let toItems = [];
        params.map(param => {
            if(param.includes('(principal)')){
                toItems.push({
                    name: param.replace('(principal)', '').trim(),
                    subitems: [],
                    url: null
                });
            } else if(param.includes('(')) {
                let url = getUrl(param);
                toItems.push({
                    name: param.replace(`(${url})`, '').trim(),
                    subitems: [],
                    url
                })
            } else {
                toItems[toItems.length - 1].subitems.push({
                    name: param
                });
            }
        })
        setItems(toItems);
    }

    const getUrl = param => {
        let url = getFromBetween.get(param, '(', ')')[0];
        return url;
    }

    return (
        <header id="header">
            <div className="container">
                <a href="https://mediaroom.jbs.com.br/">
                    <img id="logo" src={ require(`../../assets/images/logo.png`)} alt={`JBS`} />
                </a>
                <ul className="main">
                    <Menu items={items} />
                </ul>

                <div className="btn-mobile" onClick={() => toggleShow(!show)}>
                    <div className="row-btn-mobile row-f"></div>
                    <div className="row-btn-mobile row-s"></div>
                    <div className="row-btn-mobile row-t"></div>
                </div>
                {show &&
                    <>
                    <ul className="main-mobile">
                        <Menu items={items} />
                    </ul>
                </>
                }
            </div>
        </header>
    );
}

export default Header;