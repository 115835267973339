import React, { useEffect, useState } from "react";
import axios from "axios";
import { getArrayFromTableContents } from "../../helpers/helpers";

const Footer = () => {
    const [post, setPost] = useState(null);
    const [links, setLinks] = useState([]);
    const [medias, setMedias] = useState([]);
    const [bullets, setBullets] = useState([]);
    const [columns, setColumns] = useState([]);

    useEffect(() => {
        getPosts();
    }, []);

    const getPosts = () => {
        axios.get(`https://mediaroom.jbs.com.br/getposts.php`, {
            params: {
                bracket: 2,
                // slice: "Header",
                fullurl: '',
                // nolinks: '',
                // forcelinks: '',
                bullet: '',
                // iframe: '',
            }
        }).then(res => {
            console.log('footer', res);
            let post = res.data.posts.filter(post => post.slice === 'Footer')[0];
            if(post === undefined) return;

            let toColumns = getArrayFromTableContents(post.contents);
            toColumns = toColumns.length === 1 ? toColumns[0] : toColumns;
            setColumns(toColumns);

            setBullets(post.bullets);
            setPost(post);
            let toLinks = [];
            let toMedias = [];
            post.links.map(link => {
                if(['#FB#', '#IG#', '#IN#', '#YT#', '#WP#'].includes(link.linktext)){
                    toMedias.push(link);
                } else {
                    toLinks.push(link);
                }
            });
            setLinks(toLinks);
            setMedias(toMedias);
        });
    }

    return (
        <footer>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="d-flex logo-flex">
                            <img src={ require(`../../assets/images/logo.png`)} alt={`JBS`} />
                            <p>{post?.title}</p>
                        </div>
                    </div>
                </div>
                {/* <div dangerouslySetInnerHTML={{__html: post.contents }} /> */}
                <div className="row">
                    {columns.map((column, index) => (
                        <div key={index} className="col-md">
                            <div dangerouslySetInnerHTML={{__html: column }} />
                        </div>
                    ))}
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="d-flex d-flex-terms">
                            {bullets.map((bullet, index) => (
                                <>
                                    <span key={index} dangerouslySetInnerHTML={{__html: bullet }} />
                                    <p>•</p>
                                </>
                            ))}
                            <div className="d-flex-mobile">
                                {medias.map((media, index) => (
                                    <a key={index} target={`_blank`} href={media.link}>
                                        {media.linktext === '#IG#' &&
                                            <img src={ require(`../../assets/images/svgs/instagram.svg`).default } />
                                        }
                                        {media.linktext === '#YT#' &&
                                            <img src={ require(`../../assets/images/svgs/youtube.svg`).default } />
                                        }
                                        {media.linktext === '#IN#' &&
                                            <img src={ require(`../../assets/images/svgs/linkedin.svg`).default } />
                                        }
                                        {media.linktext === '#FB#' &&
                                            <img src={ require(`../../assets/images/svgs/facebook.svg`).default } />
                                        }
                                        {media.linktext === '#WP#' &&
                                            <img src={ require(`../../assets/images/svgs/whatsapp.svg`).default } />
                                        }
                                    </a>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;