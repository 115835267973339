import React from "react";
import Title from "../Title";

const Text = ({post}) => {
    return (
        <div className="container">
            <Title post={post} />
            <p dangerouslySetInnerHTML={{__html: post.contents }} />
        </div>
    );
}

export default Text;