import React from "react";

import Identify from "../Identify";
import Image from "../Image";
import Title from "../Title";

const Intro = ({intro}) => {    
    return (
        <div className="intro">
            <div className="intro-contents">
                <div className="container">
                    <div className="intro-text">
                        <Title h={`h3`} post={intro} />
                        <p dangerouslySetInnerHTML={{__html: intro.contents }} />
                        {intro.pics.length > 0 &&
                            <div className="circle-dotted"></div>
                        }
                    </div>
                </div>
            </div>
            {/* <div className="container">
                <div className="col-6">
                    
                </div>
            </div> */}
            {intro.pics.length > 0 &&
                <Image post={intro} className="image" />
            }

        </div>
    )
}

export default Intro;