export const getStrInDelimiters = (str, start, end) => {
    if(!str.includes(start)) return '';

    let res = '';
    let startPosition = str.indexOf(start) + start.length;
    let endPosition = str.lastIndexOf(end);
    let partial = str.substring(startPosition, endPosition);

    for (let i = 0; i < partial.length; i++) {
        if(partial[i] !== end){
            res += partial[i];
        } else {
            break;
        }
    }

    return res;
}

export const getFromBetween = {
    results:[],
    string:"",
    getFromBetween:function (sub1,sub2) {
        if(this.string.indexOf(sub1) < 0 || this.string.indexOf(sub2) < 0) return false;
        var SP = this.string.indexOf(sub1)+sub1.length;
        var string1 = this.string.substr(0,SP);
        var string2 = this.string.substr(SP);
        var TP = string1.length + string2.indexOf(sub2);
        return this.string.substring(SP,TP);
    },
    removeFromBetween:function (sub1,sub2) {
        if(this.string.indexOf(sub1) < 0 || this.string.indexOf(sub2) < 0) return false;
        var removal = sub1+this.getFromBetween(sub1,sub2)+sub2;
        this.string = this.string.replace(removal,"");
    },
    getAllResults:function (sub1,sub2) {
        // first check to see if we do have both substrings
        if(this.string.indexOf(sub1) < 0 || this.string.indexOf(sub2) < 0) return;

        // find one result
        var result = this.getFromBetween(sub1,sub2);
        // push it to the results array
        this.results.push(result);
        // remove the most recently found one from the string
        this.removeFromBetween(sub1,sub2);

        // if there's more substrings
        if(this.string.indexOf(sub1) > -1 && this.string.indexOf(sub2) > -1) {
            this.getAllResults(sub1,sub2);
        }
        else return;
    },
    get:function (string,sub1,sub2) {
        this.results = [];
        this.string = string;
        this.getAllResults(sub1,sub2);
        return this.results;
    }
};

export const getOptionsFromContents = contents => {
  
    if(!contents) return;
    let stringOptions = contents.substring(
        contents.indexOf("<p>") + 3, 
        contents.lastIndexOf("</p>")
    );
    let arrString = [...stringOptions];
    let getting = false;
    let stringToArray = '';
    let values = [];
    let position = 0;
    let stopHTMLtag = false;
    arrString.map(str => {
      if(str === ']'){
        if(stringToArray === 'next') position++;
        if(values[position] === undefined) values[position] = [];
        if(stringToArray !== 'next') values[position].push(stringToArray);
        stringToArray = '';
        getting = false;
        return;
      }
      if(str === '['){
        getting = true;
        return;
      }
      if(str === '<'){
        stopHTMLtag = true;
      }
      if(getting && !stopHTMLtag){
        stringToArray = stringToArray + str;
      }
      if(stopHTMLtag && str === '>'){
        stopHTMLtag = false;
      }
    });
  
    return values;
  }

  export const clearCheckmark = string => {
    string = string.replace('(checkmark)', '');
    string = string.trim();
    return string;
  }

  export const clearPrincipal = string => {
    string = string.replace('(principal)', '');
    string = string.trim();
    return string;
  }
  
  export const getGroupOptions = options => {
    let groups = [];
  
    options.map(option => {
      if(option.includes('(principal)')){
        groups.push({
          name: clearPrincipal(option),
          options: []
        });
      } else {
        groups[groups.length - 1].options.push(option);
      }
    });
  
    return groups;
  }

  export const doubleLineBreak = text => {
    if(text === null || text === false || text === undefined) return '';
    let res = text.replaceAll('[#]', `<br /><br />`);
    return res;
}

export const getTableFromString = str => {
  let tableStr = '<table>' + getFromBetween.get(str, '<table>', '</table>') + '</table>';
  let tableHtml = createElementFromHTML(tableStr);
  return tableHtml;
}

export const getArrayFromTable = element => {
  let data = element.rows;
  let arr = [];
  for (var i = 0; i < data.length; i++) {
      let el = data[i].children;
      let part = [];
      for (var j = 0; j < el.length; j++) {
          part.push(el[j].innerHTML);
      }
      arr.push(part);
  }
  return arr
}

export const getArrayFromTableContents = str => {
  let el = getTableFromString(str);
  return getArrayFromTable(el);
}

const createElementFromHTML = str => {
  var div = document.createElement('div');
  div.innerHTML = str.trim();
  return div.firstChild;
}