import React, { useRef, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { Col, Row } from 'react-bootstrap';

import api, { base } from '../../services/api';
import { stringToVar, intToZip, zipToInt, cleanPhone } from '../../helpers/conversions'
import { getOptionsFromContents, clearCheckmark, getGroupOptions } from '../../helpers/helpers'
import { doubleLineBreak } from '../../helpers/helpers';
import { convertToCep, convertToPhone } from '../../helpers/masks';

import { HiOutlineTrash } from 'react-icons/hi';
import { BsArrowLeftSquareFill, BsArrowRightSquareFill } from 'react-icons/bs';
import { MdKeyboardArrowLeft, MdKeyboardArrowDown } from 'react-icons/md';
import { GrDrag } from 'react-icons/gr';
import { IconContext } from 'react-icons';

import RLDD from 'react-list-drag-and-drop/lib/RLDD';

import { components } from 'react-select';
import axios from 'axios';
import FormIntro from '../../components/FormIntro';
import Header from '../../components/Header';
import Footer from '../../components/Footer';

const addressSlice = 'Correspondência';
const ufs =  ['AC', 'AL', 'AP', 'AM', 'BA', 'CE', 'DF', 'ES', 'GO', 'MA', 'MT', 'MS', 'MG', 'PA', 'PB', 'PR', 'PE', 'PI', 'RJ', 'RN', 'RS', 'RO', 'RR', 'SC', 'SP', 'SE', 'TO'];
const contentUfs = '[AC] [AL] [AP] [AM] [BA] [CE] [DF] [ES] [GO] [MA] [MT] [MS] [MG] [PA] [PB] [PR] [PE] [PI] [RJ] [RN] [RS] [RO] [RR] [SC] [SP] [SE] [TO]';

const Group = ({group, index, handleRemoveGroup, handleInputChange, handleAddInput, handleRemoveInput}) => {
  return (
    <section id={`group-${group.id}`} className="section-group">
      <fieldset>
        <legend>
          {group.name}
          {index !== 0 &&
            <button type="button" className="btn-remove-group" onClick={() => handleRemoveGroup(group.id)}><HiOutlineTrash /></button>
          }
        </legend>
        <div className="section-inputs">
          {group.inputs.map((input, index) => (
            <Input key={index} input={input} group={group} handleInputChange={handleInputChange} handleAddInput={handleAddInput} handleRemoveInput={handleRemoveInput} />
          ))}
        </div>
      </fieldset>
    </section>
  );
}

const SectionGroups = ({section, groups, baseGroups, handleAddGroup, handleRemoveGroup, handleInputChange, handleAddInput, handleRemoveInput}) => {
  return (
    <section id={`section-groups-${section}`} className="section-groups">
      {baseGroups.map((baseGroup, index) => (
        <button key={index} type="button" className="btn-more-group" onClick={() => handleAddGroup(baseGroup)}>+ {baseGroup.name}</button>
      ))}

      {groups.map((group, index) => (
          <Group key={index} group={group} index={index} handleRemoveGroup={handleRemoveGroup} handleInputChange={handleInputChange} handleAddInput={handleAddInput} handleRemoveInput={handleRemoveInput} />
      ))}
    </section>
  );
}

const Section = ({section, inputs, groups, baseGroups, handleInputChange, handleAddGroup, handleRemoveGroup, handleAddInput, handleRemoveInput}) => {
  return (
    <section className="section">
      {/* <h2 className="section-title">{section}</h2> */}
      <SectionGroups 
        section={section} 
        groups={groups.filter(g => g.section === section)} 
        baseGroups={baseGroups.filter(g => g.section === section)} 
        handleAddInput={handleAddInput}
        handleRemoveInput={handleRemoveInput}
        handleAddGroup={handleAddGroup}
        handleRemoveGroup={handleRemoveGroup}
        handleInputChange={handleInputChange}
      />
      <div className="section-inputs">
        {inputs.filter(i => i.section === section).map((input, index) => (
          <Input key={index} input={input} handleInputChange={handleInputChange} handleAddInput={handleAddInput} handleRemoveInput={handleRemoveInput} />
        ))}
      </div>
    </section>
  );
}

const Input = ({input, handleInputChange, group = null, handleAddInput, handleRemoveInput}) => {
  switch (input.type !== undefined ? input.type.toLowerCase() : undefined) {
    case 'aberta':
      return <InputText input={input} group={group} handleInputChange={handleInputChange} handleAddInput={handleAddInput} handleRemoveInput={handleRemoveInput} />

    case 'dropdown':
      return <InputDropdown input={input} group={group} handleInputChange={handleInputChange} handleAddInput={handleAddInput} handleRemoveInput={handleRemoveInput} />
    
      case 'cargo':
      return <InputPosition input={input} group={group} handleInputChange={handleInputChange} handleAddInput={handleAddInput} handleRemoveInput={handleRemoveInput} />

    case 'múltipla escolha':
      return <InputCheckbox input={input} group={group} handleInputChange={handleInputChange} handleAddInput={handleAddInput} handleRemoveInput={handleRemoveInput} />

    case 'opção única':
      return <InputRadio input={input} group={group} handleInputChange={handleInputChange} handleAddInput={handleAddInput} handleRemoveInput={handleRemoveInput} />
    
    case 'box':
      return <InputBox input={input} group={group} handleInputChange={handleInputChange} handleAddInput={handleAddInput} handleRemoveInput={handleRemoveInput} />
    
    case 'box ranking':
      return <InputBoxRanking input={input} group={group} handleInputChange={handleInputChange} handleAddInput={handleAddInput} handleRemoveInput={handleRemoveInput} />
    
    case undefined:
      return <></>

    default:
      return <div className="form-group"><p>Tipo de campo não configurado: {input.type}</p></div>
  }
}

const InputBoxRanking = ({input, handleInputChange, group, handleAddInput, handleRemoveInput}) => {
  const [options, setOptions] = useState([]);
  const [values, setValues] = useState([]);
  const [nothing, setNothing] = useState(false);
  const [textNothing, setTextNothing] = useState('');

  useEffect(() => {
    getOptions();
  }, [input]);

  const getOptions = ()  => {
    let toOptions = [];
    let toNothing = false;
    let originOptions = input.options[0];
    let inputValues = input.values;

    originOptions.map((opt, index) => {
      if(opt.includes('checkmark')){
        originOptions = originOptions.filter(oo => oo !== opt);
        setTextNothing( clearCheckmark(opt) );
      }
    });

    let i = 0;

    if(inputValues[0] === ''){
      toNothing = true;
    } else {
      inputValues.map(v => {
        if(originOptions.includes(v)){
          toOptions.push({ id: i, value: v });
          i++;
        }
      });
    }

    originOptions.map(oo => {
      if(toOptions.filter(to => to.value === oo).length === 0 && oo !== textNothing){
        toOptions.push({ id: i, value: oo});
        i++;
      }
    });

    setOptions(toOptions);
    setNothing(toNothing);
    updateInput(toOptions);
  }

  const updateInput = values => {
    let toValues = [];
    values.map(v => toValues.push(v.value));
    toValues = toValues.length === 0 ? [''] : toValues;
    toValues = toValues[0] === textNothing ? [''] : toValues;
    const e = {
      target: {
        name: input.name,
        checked: null,
        type: null,
        value: toValues
      }
    };
    handleInputChange(e, 0, false, group);
  }

  const handleRLDDChange = (newOptions) => {
    updateInput(newOptions);
    setOptions(newOptions);
  }

  useEffect(() => {
    if(nothing){
      updateInput([{value: textNothing}]);
    }
  }, [nothing]);

  
  return (
    <div className="form-group" style={{ width: input.size }}>
      <label>{input.label}{input.required ? ' *' : ''}</label>
      <p className="sublabel">{input.sublabel}</p>
      {input.error !== '' &&
        <span className="input-error">{input.error}</span>
      }
      <div className="input-checkbox-group ranking">
        <input 
          id="checkNothing"
          type="checkbox"
          checked={nothing}
          onChange={() => setNothing(prev => !prev)}
        />
        <label htmlFor="checkNothing">{textNothing}</label>
      </div>
      {!nothing &&
        <div className="input-box-ranking-group">
          <div className="input-box-ranking-col">
            <RLDD
              items={options}
              itemRenderer={(item, index) => {
                return (
                  <div className="input-box-ranking-option">
                    <div className="ranking-position">
                      {index+1}º
                    </div>
                    <div className="ranking-content">
                      <GrDrag className="drag-icon" />
                      {item.value} 
                    </div>
                  </div>
                );
              }}
              onChange={handleRLDDChange}
            />
          </div>
        </div>
      }
    </div>
  )
}

const InputBox = ({input, handleInputChange, group, handleAddInput, handleRemoveInput}) => {
  const [edts, setEdts] = useState({});
  const [availableEdts, setAvailableEdts] = useState({});
  const [chosenEdts, setChosenEdts] = useState({});

  const [availableActive, setAvailableActive] = useState(null);
  const [chosenActive, setChosenActive] = useState(null);

  const [selectedEdts, setSelectedEdts] = useState([]);
  const [selectedChosen, setSelectedChosen] = useState([]);

  const [values, setValues] = useState([]);

  useEffect(() => {
    api(`/getposts.php?slice=formfields&edt`).then(res => {
      let toEdts = res.data?.edt ? res.data.edt : {};
      setEdts(toEdts);
      setAvailableEdts(toEdts);
    });
  }, []);

  useEffect(() => {
    if(input.values.length > 0 && Object.keys(edts).length > 0) setValues(input.values);
  }, [input, edts]);

  useEffect(() => {
    const e = {
      target: {
        name: input.name,
        checked: null,
        type: null,
        value: values
      }
    };
    handleInputChange(e, 0, false, group);
    filterEdts();
    setSelectedEdts([]);
    setSelectedChosen([]);
  }, [values]);

  const filterEdts = () => {
    let toAvailableEdts = {};
    let toChosenEdts = {};
    Object.keys(edts).map(edtg => {
      Object.keys(edts[edtg]).map(edt => {
        let val = `${edtg}.${edts[edtg][edt]}`;
        if(!values.includes(val)){
          if(!toAvailableEdts[edtg]){
            toAvailableEdts[edtg] = [];
          }
          toAvailableEdts[edtg].push(edts[edtg][edt]);
        } else {
          if(!toChosenEdts[edtg]){
            toChosenEdts[edtg] = [];
          }
          toChosenEdts[edtg].push(edts[edtg][edt]);
        }
      });
    });

    setChosenEdts(toChosenEdts);
    setAvailableEdts(toAvailableEdts);
  }

  const handleAvailableActive = index => {
    setAvailableActive(prev => prev === index ? null : index);
  }

  const handlChosenActive = index => {
    setChosenActive(prev => prev === index ? null : index);
  }

  const handleSelectedChosen = name => {
    if(!selectedChosen.includes(name)){
      setSelectedChosen(prev => [...prev, name]);
    } else {
      setSelectedChosen(prev => prev.filter(p => p !== name));
    }
  }

  const checkSelectedChosen = name => {
    return selectedChosen.includes(name);
  }

  const handleSelectedEdt = name => {
    if(!selectedEdts.includes(name)){
      setSelectedEdts(prev => [...prev, name]);
    } else {
      setSelectedEdts(prev => prev.filter(p => p !== name));
    }
  }

  const checkSelectedEdt = name => {
    return selectedEdts.includes(name);
  }

  const sendToValues = () => {
    let toValues = [...values, ...selectedEdts];
    toValues = [... new Set(toValues)];
    setValues(toValues);
  }

  const backFromValues = () => {
    let toValues = values.filter(v => !selectedChosen.includes(v));
    setValues(toValues);
  }
  
  return (
    <div className="form-group" style={{ width: input.size }}>
      <label>{input.label}{input.required ? ' *' : ''}</label>
      <p className="sublabel">{input.sublabel}</p>
      {input.error !== '' &&
        <span className="input-error">{input.error}</span>
      }

      <div className="input-box-group">
        <div className="input-box-col">
          <ul>
            {Object.keys(availableEdts).map((aedtgroup, index) => (
              <li key={index}>
                <button type="button" onClick={() => handleAvailableActive(index)}>
                  {aedtgroup} {availableActive === index ? <MdKeyboardArrowDown size="15px" /> : <MdKeyboardArrowLeft size="15px" />}
                </button>
                <ul className={availableActive === index ? 'active' : ''}>
                  {Object.keys(availableEdts[aedtgroup]).map((aedt, index) => (
                    <li key={index}>
                      <button 
                        type="button"
                        className={checkSelectedEdt(`${aedtgroup}.${availableEdts[aedtgroup][aedt]}`) ? 'selected' : ''}
                        onClick={() => handleSelectedEdt(`${aedtgroup}.${availableEdts[aedtgroup][aedt]}`)}
                      >
                        {availableEdts[aedtgroup][aedt]}
                      </button>
                    </li>
                  ))}
                </ul>
              </li>
            ))}
          </ul>
        </div>

        <div className="input-box-center">
          <button type="button" onClick={() => sendToValues()}><BsArrowRightSquareFill size="35px" /></button>
          <button type="button" onClick={() => backFromValues()}><BsArrowLeftSquareFill size="35px" /></button>
        </div>

        <div className="input-box-col">
          {values.length === 0 &&
            <p className="input-box-message">Por favor, escolha as opções do quadro ao lado.</p>
          }
          <ul>
            {Object.keys(chosenEdts).map((cedtgroup, index) => (
              <li key={index}>
                <button type="button" onClick={() => handlChosenActive(index)}>
                  {cedtgroup} {chosenActive === index ? <MdKeyboardArrowDown size="15px" /> : <MdKeyboardArrowLeft size="15px" />}
                </button>
                <ul className={chosenActive === index ? 'active' : ''}>
                  {Object.keys(chosenEdts[cedtgroup]).map((cedt, index) => (
                    <li key={index}>
                      <button 
                        type="button"
                        className={checkSelectedChosen(`${cedtgroup}.${chosenEdts[cedtgroup][cedt]}`) ? 'selected' : ''}
                        onClick={() => handleSelectedChosen(`${cedtgroup}.${chosenEdts[cedtgroup][cedt]}`)}
                      >
                        {chosenEdts[cedtgroup][cedt]}
                      </button>
                    </li>
                  ))}
                </ul>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

const InputText = ({input, handleInputChange, group, handleAddInput, handleRemoveInput}) => {
  const generateInputs = () => {
    let arrInputs = [];
    
    for (let index = 0; index < input.values.length; index++) {
      arrInputs.push(
        <div key={index} className="input-array-group">
          <input type="text" name={input.name} value={input.values[index]} onChange={e => handleInputChange(e, index, false, group)} />
          {index > 0 && input.array &&
            <button type="button" className="btn-input-delete" onClick={() => handleRemoveInput(input, index, group)}><HiOutlineTrash /></button>
          }
        </div>
      );
    }

    return arrInputs;
  }

  return (
    <div className="form-group" style={{ width: input.size }}>
      <label>{input.label}{input.required ? ' *' : ''}</label>
      <p className="sublabel">{input.sublabel}</p>
      {input.error !== '' &&
        <span className="input-error">{input.error}</span>
      }

      {generateInputs()}
      
      {input.values.length < 2 && input.array &&
        <button type="button" className="btn-add-array" onClick={() => handleAddInput(input, group)}>+ {input.label}</button>
      }
    </div>
  );
}

const InputDropdown = ({input, handleInputChange, group}) => {
  let groupClass = input.options.length > 1 ? 'multiple' : 'unique';

  return (
    <div className="form-group" style={{ width: input.size }}>
      <label>{input.label}{input.required ? ' *' : ''}</label>
      <p className="sublabel">{input.sublabel}</p>
      {input.error !== '' &&
        <span className="input-error">{input.error}</span>
      }
      <div className={`input-select-group ${groupClass}`}>
        {input.options.map((opts, index) => (
          <select
            key={index}
            name={input.name}
            value={input.values[index]} 
            onChange={e => handleInputChange(e, index, false, group)}
          >
            <option>Selecione....</option>
            {opts.map((v, index) => (
              <option key={index} value={v}>{v}</option>
            ))}
          </select>
        ))}
      </div>
      {input.values.includes('Outros') &&
        <input type="text" name={input.name} onChange={e => handleInputChange(e, 0, true, group)} value={input.other} />
      } 
    </div>
  );
}

const InputPosition = ({input, handleInputChange, group}) => {
  const options = input.options.length > 0 ? input.options[0] : [0];
  const groupOptions = getGroupOptions(options);

  return (
    <div className="form-group" style={{ width: input.size }}>
      <label>{input.label}{input.required ? ' *' : ''}</label>
      <p className="sublabel">{input.sublabel}</p>
      {input.error !== '' &&
        <span className="input-error">{input.error}</span>
      }
      <div className="input-select-group">
          <select
            name={input.name}
            value={input.values[0]} 
            onChange={e => handleInputChange(e, 0, false, group)}
          >
            <option>Selecione....</option>
            {groupOptions.map((g, index) => (
              <optgroup key={index} label={g.name}>
                {g.options.map((o, index) => (
                  <option key={index} value={o}>{o}</option>
                ))}
              </optgroup>
            ))}
          </select>
      </div>
      {input.values.includes('Outros') &&
        <input type="text" name={input.name} onChange={e => handleInputChange(e, 0, true, group)} value={input.other} />
      } 
    </div>
  );
}

const InputRadio = ({input, handleInputChange, group}) => {

  let options = input.options[0];

  return (
    <>
      <div className="form-group" style={{ width: input.size }}>
        <form>
          <label>{input.label}{input.required ? ' *' : ''}</label>
          <p className="sublabel">{input.sublabel}</p>
          {input.error !== '' &&
            <span className="input-error">{input.error}</span>
          }
          {options.map((val, index) => (
            <div key={index} className="input-radio-group">
              <input 
                id={`${input.name}${group?.id ? group.id : ''}${index}`} 
                type="radio" 
                name={input.name} 
                value={val} 
                onChange={e => handleInputChange( e, 0, false, group)}
                checked={val === input.values[0]}
              />
              <label htmlFor={`${input.name}${group?.id ? group.id : ''}${index}`}>{val}</label>
            </div>
          ))}
          {input.values.includes('Outros') &&
            <input type="text" name={input.name} onChange={e => handleInputChange(e, 0, true, group)} value={input.other} />
          }
        </form>
      </div>
    </>
  );
}

const InputCheckbox = ({input, handleInputChange, group}) => {
  let options = input.options[0];

  return (
    <>
      <div className="form-group" style={{ width: input.size }}>
        <label>{input.label}{input.required ? ' *' : ''}</label>
        <p className="sublabel">{input.sublabel}</p>
        {input.error !== '' &&
          <span className="input-error">{input.error}</span>
        }
        {options.map((val, index) => (
          <div key={index} className="input-checkbox-group">
            <input 
              // id={`${input.id}${index}`} 
              type="checkbox"
              name={input.name} 
              value={val}
              onChange={e => handleInputChange(e, 0, false, group)}
              checked={input.values.includes(val)}
            />
            <label htmlFor={`${input.id}${index}`}>{val}</label>
          </div>
        ))}
        {input.values.includes('Outros') &&
          <input type="text" name={input.name} onChange={e => handleInputChange(e, 0, true, group)} value={input.other} />
        }
      </div>
    </>
  );
}

const Form = () => {  
  const [edts, setEdts] = useState({});
  const [sections, setSections] = useState([]);
  const [inputs, setInputs] = useState([]);
  const [baseGroups, setBaseGroups] = useState([]);
  const [groups, setGroups] = useState([]);
  const [intro, setIntro] = useState(null);
  const [dataRead, setDataRead] = useState(false);
  const [dataObj, setDataObj] = useState(null);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');

  useEffect(() => {
    getPosts();
  }, []);

  const getPosts = () => {
    axios.get(`https://mediaroom.jbs.com.br/getposts.php`, {
        params: {
            bracket: 2,
            slice: "Form",
            fullurl: '',
            nolinks: '',
            bullet: '',
            iframe: '',
        }
    }).then(res => {
        console.log(res);
        let toEdts = res.data?.edt ? res.data.edt : {};
        let toSections = ['Form'];
        // let toSections = res.data.formfieldorder;
        let toInputs = getInputs(res.data.posts);
        let toGroups = getGroups(res.data.posts);
        let toBaseGroups = getGroups(res.data.posts);
        setEdts(toEdts);
        setInputs(toInputs);
        setGroups(toGroups);
        setBaseGroups(toBaseGroups);
        setSections(toSections);
        setIntro(res.data.intro.length > 0 ? res.data.intro[0] : null);
    });
  }

  useEffect(() => {
    if(inputs.length > 0 && !dataRead){
      getData();
      setDataRead(true);
    }
  }, [inputs, groups, baseGroups]);

  const hydrateBoxValues = values => {
    let res = [];
    Object.keys(edts).map(edtg => {
      edts[edtg].map(edt => {
        if(values.includes(edt)){
          res.push(`${edtg}.${edt}`);
        }
      });
    });
    return res;
  }

  const getData = () => {
    let params = (new URL(document.location)).searchParams;
    let cpf = params.get("cpf");
    let ano_nascimento = params.get("ano_nascimento");

    window.checkCPF(cpf, ano_nascimento, 0, function(i,d) {
      console.log('checkCPF', JSON.parse(d.responseText))
      setData(JSON.parse(d.responseText));
    });
  }

  const setData = data => {
    setDataObj(data);
    if(data?.name !== null){
      setName(data.name);
    }
    let toInputs = [];
    inputs.map(i => {
      let ti = {...i};
      let values = [];
      let formfield = i.formfield !== null ? i.formfield.replace('[]', '') : null;
      console.log('formfi', formfield);
      if(formfield !== null){
        let keys = formfield.split('|');
        keys.map(key => {
          let val = data[key];
          if(formfield.includes('.')){
            let keys = formfield.split('.');
            if(data[keys[0]] !== undefined){
              val = data[keys[0]][keys[1]];
            }
          }

          if(val !== undefined && val !== null){
            if(val.constructor === Array){
              values.push(...val);
            } else {
              values.push(val);
            }
          }
        });
        
      }
      values = values.length === 0 ? [''] : values;
      
      if(i.section === 'Consentimento' && i.type === 'dropdown'){
        if(data.consent !== undefined){
          values = [getConcentValues(i, data.consent)];
        } else {
          values = ['']
        }
      }
      values.map((v, index) => {
        values[index] = applyMask(v, i);
      });
      toInputs.push({...ti, values});
    });
    setInputs(toInputs);

    let toGroups = [];
    groups.map(g => {
      let ffs = g.formfield ? g.formfield.split('.') : [];
      let ff = ffs[0];
      // let ff = ffs[1] === undefined ? ffs[0] : ffs[1];
      if(data[ff] !== undefined){
        let base = baseGroups.filter(b => b.name === g.name)[0];
        let item = data[ff].constructor === Array ? data[ffs[0]] : data[ffs[0]][ffs[1]];
        for (let index = 0; index < item.length; index++) {
          let newGroup = {...base};
          let toGroupInputs = [];
          base.inputs.map(gi => {
            let arrParam = gi.formfield.split('.');
            let param = arrParam[arrParam.length -1];
            param = param.replace('[]', '');
            let values = item[index][param];
            if(values === undefined){
              values = [''];
            } else {
              if(values.constructor !== Array) values = [values];
              if(gi.type === 'Box'){
                values = hydrateBoxValues(values);
              }
            }
            values.map((v, index) => {
              if(gi.tags.includes('CEP')){
                values[index] = intToZip(values[index]);
              }
              if(gi.tags.includes('uppercase')){
                values[index] = values[index].toUpperCase();
              }
            });
            values = values.length === 0 ? [''] : values;
            toGroupInputs.push({
              ...gi,
              values
            });
          });
          newGroup.inputs = toGroupInputs;
          toGroups.push(newGroup);
        }
      } else {
        toGroups.push(g);
      }
    });
    setGroups(toGroups);
  }

  const getGroupFormfield = inputs => {
    if(inputs.length === 0) return null;
    if(!inputs[0]?.formfield) return null;
    let arr = inputs[0].formfield.split('.');
    if(arr.length === 3){
      return arr[0] + '.' + arr[1].replace('[]', '');
    } else {
      return arr[0].replace('[]', '');
    }
    
  }

  const getGroups = posts => {
    let groups = [];
    let groupsInputs = [];

    posts.map(post => {
      if(post.meta.length > 0){
        if(!groups.includes(post.meta[0])) groups.push(post.meta[0]);
      }
    });

    // groups.push(addressSlice);

    groups.map((group, index) => {
      let filteredPosts = posts.filter(p => {
        if(p.meta){
          if(p.meta.includes(group)) return true;
        }
        return false;
      });
      filteredPosts = group === addressSlice ? generateAddressPosts(addressSlice) : filteredPosts;

      let inputs = getInputs(filteredPosts, true);
      let groupInput = {
        id: new Date().valueOf() + index,
        name: group,
        section: inputs[0].section,
        formfield: getGroupFormfield(inputs),
        inputs
      };

      groupsInputs.push(groupInput);
    });

    return groupsInputs;
  }

  const generateAddressPosts = (addressSlice) => {
    let otherData = {
      slice: addressSlice,
    };

    let posts = [];

    posts.push({
      title: 'CEP',
      cards: ['aberta'],
      tags: ['Tag P', 'CEP', 'optional'],
      contents: '',
      formfield: 'address[].zip',
      ...otherData
    });

    posts.push({
      title: 'Descrição',
      cards: ['aberta'],
      tags: ['Tag M', 'optional'],
      contents: '',
      formfield: 'address[].desc',
      ...otherData
    });

    posts.push({
      title: 'Endereço',
      cards: ['aberta'],
      tags: ['Tag G', 'endereco', 'optional'],
      contents: '',
      formfield: 'address[].streetaddress',
      ...otherData
    });

    posts.push({
      title: 'Número',
      cards: ['aberta'],
      tags: ['Tag M', 'optional'],
      contents: '',
      formfield: 'address[].number',
      ...otherData
    });

    posts.push({
      title: 'Complemento',
      cards: ['aberta'],
      tags: ['Tag M', 'optional'],
      contents: '',
      formfield: 'address[].extra',
      ...otherData
    });

    posts.push({
      title: 'UF',
      cards: ['dropdown'],
      tags: ['Tag P', 'uf', 'uppercase', 'optional'],
      contents: `<p>${contentUfs}</p>`,
      formfield: 'address[].state',
      ...otherData
    });

    posts.push({
      title: 'Cidade',
      cards: ['aberta'],
      tags: ['Tag P', 'cidade', 'optional'],
      contents: '',
      formfield: 'address[].town',
      ...otherData
    });

    posts.push({
      title: 'Bairro',
      cards: ['aberta'],
      tags: ['Tag P', 'bairro', 'optional'],
      contents: '',
      formfield: 'address[].neigh',
      ...otherData
    });

    return posts;
  }

  const getInputs = (posts, withGroup = false) => {
    let inputs = [];
    
    posts.map(post => {
      if(!withGroup && post.meta.length > 0) return;
      let inputData = getInputData(post);
      inputs.push(inputData);
    });

    return inputs;
  }

  const getInputData = post => {
    let input = {};

    let type = post.cards[0];
    let options = getOptionsFromContents(post.contents);

    let name = stringToVar(post.title);

    input['label'] = post.title;
    input['sublabel'] = post.description;
    input['name'] = name;
    input['required'] = post.tags.includes('optional') ? false : true;
    input['section'] = post.slice;
    input['type'] = type;
    input['options'] = options;
    input['array'] = post.tags.includes('array');
    input['quantity'] = 1;
    input['values'] = [''];
    input['other'] = '';
    input['error'] = '';
    input['tags'] = post.tags;
    input['formfield'] = post?.formfield ? post.formfield : null;

    let size = '100%';
    if(post.tags.includes('Tag M')){
      size = '50%';
    }
    if(post.tags.includes('Tag P')){
      size = '33.33333333%';
    }

    input['size'] = size;

    return input;
  }

  const handleInputChange = (e, index = 0, other = false, group) => {
    if(group === null){
      let toInputs = [...inputs];
      toInputs = updateInputs(toInputs, e, index, other, group);
      setInputs(toInputs);
    } else {
      let toGroups = [...groups];
      toGroups.map((g, i) => {
        if(g.id === group.id){
          let toInputs = [...g.inputs];
          let updatedInputs = updateInputs(toInputs , e, index, other, group);
          toGroups[i].inputs = updatedInputs;
        }
      });
      setGroups(toGroups);
    }
  }

  const updateInputs = (toInputs, e, index, other, group) => {
    
    const target = e.target;
    let value = target.value;
    const name = target.name;
    const checked = target.checked;
    const type = target.type;

    toInputs.map((input, i) => {
      if(name === input.name){
        toInputs[i].error = '';

        if(type === 'checkbox'){
          if(toInputs[i].values.includes(value) && !checked){
            toInputs[i].values = toInputs[i].values.filter(v => v !== value);
          }
          if(!toInputs[i].values.includes(value) && checked){
            toInputs[i].values.push(value);
          }
        } else {
          if(other){
            toInputs[i].other = value;
          } else {
            if(Array.isArray(value)){
              toInputs[i].values = value;
            } else {
              value = applyMask(value, input);
              if(input.tags.includes('CEP') && value.length === 9){
                getAddress(value, group);
              }
              toInputs[i].values[index] = value;
            }
          }
        }
      };
    });

    return toInputs;
  }

  const getAddress = (zip, group) => {
    let cZip = parseInt(zip.replace('-', '') );
    let address = {};
    axios.get(`https://site.i-maxpr.com/API/checkZip.php?zip=${cZip}`).then(res => {
      address.address = res.data.address;
      address.town = res.data.town;
      address.state = res.data.state;
      address.neigh = res.data.neigh;
      let toGroups = [];
      groups.map(g => {
        if(g.id === group.id){
          let gInputs = [];
          group.inputs.map(gi => {
            if(gi.tags.includes('endereco')){
              gInputs.push({...gi, values: [address.address]});
              return;
            }

            if(gi.tags.includes('cidade')){
              gInputs.push({...gi, values: [address.town]});
              return;
            }

            if(gi.tags.includes('uf')){
              gInputs.push({...gi, values: [address.state]});
              return;
            }

            if(gi.tags.includes('bairro')){
              gInputs.push({...gi, values: [address.neigh]});
              return;
            }

            gInputs.push(gi);
          });
          toGroups.push({...g, inputs: gInputs});
        } else {
          toGroups.push(g);
        }
      });
      setGroups(toGroups);
    });
  }


  const applyMask = (value, input) => {
    let { tags } = input;
    let res = value;

    if(tags.includes('CEP')){
      res = convertToCep(value);
    }

    if(tags.includes('celular') || tags.includes('telefone')){
      res = convertToPhone(value);
    }

    return res;
  }

  const handleRemoveGroup = id => {
    let toGroups = groups.filter(g => g.id !== id);
    setGroups(toGroups);
  }

  const handleAddInput = (input, group = null) => {
    if(group === null){
      let toInputs = addValueToInput(inputs, input);
      setInputs(toInputs);
    } else {
      let toGroups = [];
      groups.map(g => {
        if(group.id === g.id){
          let newGroup = {...g};
          newGroup.inputs = addValueToInput(g.inputs, input);
          toGroups.push(newGroup);
        } else {
          toGroups.push(g);
        }
      })
      setGroups(toGroups);
    }
  }

  const addValueToInput = (ins, input) => {
    let toInputs = [];

    ins.map(i => {
      if(input.name === i.name){
        let newInput = {...i};
        newInput.values.push('');
        toInputs.push(newInput);
      } else {
        toInputs.push(i);
      }
    });

    return toInputs;
  }
  
  const handleRemoveInput = (input, index, group = null) => {
    if(group === null){
      let toInputs = removeValueFromInput(inputs, input, index);
      setInputs(toInputs);
    } else {
      let toGroups = [];
      groups.map(g => {
        if(group.id === g.id){
          let newGroup = {...g};
          newGroup.inputs = removeValueFromInput(g.inputs, input, index);
          toGroups.push(newGroup);
        } else {
          toGroups.push(g);
        }
      })
      setGroups(toGroups);
    }
  }

  const removeValueFromInput = (ins, input, index) => {
    let toInputs = [];

    ins.map(i => {
      if(input.name === i.name){
        let newInput = {...i};
        let toValues = newInput.values;
        toValues = [];
        newInput.values.map((v, i) => {
          if(i !== index) toValues.push(v);
        });
        newInput.values = toValues;
        toInputs.push(newInput);
      } else {
        toInputs.push(i);
      }
    });
    return toInputs;
  }

  const handleAddGroup = baseGroup => {
    let toGroups = [...groups];
    let lastGroups = [...groups];
    let group = JSON.parse(JSON.stringify(baseGroup));

    let id = toGroups[toGroups.length - 1].id + 1;

    group.id = id;
    toGroups.push(group);
    setGroups(toGroups);
    scrollToGroup(group, lastGroups);
  }

  const scrollToGroup = (currentGroup, lastGroups) => {
    lastGroups = lastGroups.filter(g => g.name === currentGroup.name);
    let lastGroup = lastGroups[lastGroups.length -1];
    let prev = document.getElementById(`group-${lastGroup.id}`);
    window.scroll({
      top: absoluteOffset(prev) + prev.offsetHeight - 300,
      behavior: 'smooth'  // 👈 
    });
  }

  const absoluteOffset = elem => {
    return elem.offsetParent && elem.offsetTop + absoluteOffset(elem.offsetParent);
  }

  const handleFormSubmit = e => {
    e.preventDefault();
    const validation = validete();
    if(!validation){
      toast.error('Um ou mais campos precisam ser preenchidos');
      window.scrollTo( 0, 0 );
      return;
    }

    const inputsObj = getInputsObj();
    console.log(inputsObj);
    jsvalidate(inputsObj);
  }

  const jsvalidate = inputsObj => {
    window.prevalidate(inputsObj, function(i,d) {
      handleJsValidate(JSON.parse(d.responseText), inputsObj);
    });
  }

  const handleJsValidate = (data, inputsObj) => {
    if(data.errors.length > 0){
      data.errors.map(err => {
        toast.error(err);
      });
      window.scrollTo( 0, 0 );
    } else {
      setLoading(true);
      let rdct = function () {
        setLoading(false);
        window.location.href=`/Home/Thankyoupage`
      }
      console.log(inputsObj);
      window.saveForm(inputsObj);
      window.loadForm(rdct);
    }
  }

  const getConcentValues = (input, index) => {
    let { options } = input;
    return options[0][index];
  }

  const setConcentValues = (input) => {
    let { values, options } =  input;
    let res = null;
    options[0].map((op, index) => {
      if(values.includes(op)){
        res = index;
      }
    });
    return res;
  }


  const hydrateValues = input => {
    let values = [...input.values];
    values.map((v, index) => {
      if(input.tags.includes('CEP')){
        values[index] = zipToInt(v);
      }
      if(input.tags.includes('celular')){
        values[index] = cleanPhone(v);
      }
      if(input.type.toLowerCase() === 'box ranking' && values[0] === ''){
        values = null;
      }
    });
    return values;
  }

  const getInputsObj = () => {
    let obj = {};
    inputs.map(input => {
      if(input.section === 'Consentimento'){
        obj.consent = setConcentValues(input);
        return;
      }
     
      let values = hydrateValues(input);
      let ff = input.formfield;
      if(ff !== null){
        if (ff.includes('|')){
          let arr = ff.split('|');
          arr.map((key, index) => {
            obj[key] = values[index];
          });
        } else if (ff.includes('.')){
          let arr = ff.split('.');
          if(obj[arr[0]] === undefined) obj[arr[0]] = {};
          if(obj[arr[0]][arr[1] === undefined]) obj[arr[0]][arr[1]] = '';
          obj[arr[0]][arr[1].replace('[]', '')] = ff.includes('[]') ? values : values[0];
        } else {
          obj[ff.replace('[]', '')] = ff.includes('[]') ? values : values[0];
        }
      }
    });

    groups.map(group => {
      let gObj = {};

      group.inputs.map(groupinput => {
        let ff = groupinput.formfield;
        let values = hydrateValues(groupinput);
        values.map((v, index) => {
          if(groupinput.type.toLowerCase() === 'box'){
            values[index] = v.split('.')[1];
          }
        });
        if(ff != null){
          let arrFf = ff.split('.');
          let key = arrFf[arrFf.length - 1];
          if(key.includes('[]')){
            gObj[key.replace('[]', '')] = values;
          } else {
            gObj[key] = values[0];
          }
        }
      });

      let arrFf = group.inputs[0].formfield.split('.');
      let key = arrFf[arrFf.length - 2];
      key = key.replace('[]', '');
      let key2 = arrFf[arrFf.length - 3];

      if(key2 !== undefined){
        obj[key2] = obj[key2] === undefined ? {} : obj[key2];
        obj[key2][key] = obj[key2][key] === undefined ? [] : obj[key2][key];
        obj[key2][key].push(gObj);
      } else {
        obj[key] = obj[key] === undefined ? [] : obj[key];
        obj[key].push(gObj);
      }
    });
    
    return obj;
  }

  const validete = () => {
    let toInputs = [...inputs];
    let success = true;

    toInputs.map((input, index) => {
      let required = input.required;
      let n = input.options.length === 0 ? 1 : input.options.length;

      if(!required) return;

      for (let i = 0; i < n; i++) {
        let position = i;
        let val = input.values[position] === undefined ? null : input.values[position];
        if(val !== null){
          val = val.constructor === String ? val.trim() : val;
        }
        let other = input.other.trim();

        if(input.values.includes('Outros') && other === ''){
          toInputs[index].error = 'Este campo precisa ser preenchido';
          success = false;
        } else if(val === '' || val === undefined || val === null){
          toInputs[index].error = 'Este campo precisa ser preenchido';
          success = false;
        }
      }
    });
    
    setInputs(toInputs);

    return success;
  }
  return (
    <>
        <Header />
        <div id="wrapper">
            {intro &&
                <FormIntro intro={intro} name={name} />
            }
            <div className="container">
                <form className="form" onSubmit={handleFormSubmit}>
                {sections.map((section, index) => (
                    <Section
                    key={`section${index}`} 
                    section={section} 
                    inputs={inputs} 
                    groups={groups} 
                    baseGroups={baseGroups}
                    handleAddInput={handleAddInput}
                    handleRemoveInput={handleRemoveInput}
                    handleInputChange={handleInputChange}
                    handleAddGroup={handleAddGroup}
                    handleRemoveGroup={handleRemoveGroup} />
                ))}
                {(inputs.length > 0 || groups.length > 0) &&
                    <button type="submit" disabled={loading}>{loading ? 'Enviando...' : 'Enviar'}</button>
                }
                </form>
            </div>
        </div>
        <Footer />
    </>
  );
}

export default Form;