import axios from "axios";
import React, { useEffect, useState } from "react";
import MainTemplate from "../../templates/Main";


const Home = () => {
    const [posts, setPosts] = useState([]);
    const [intro, setIntro] = useState(null);

    useEffect(() => {
        getPosts();
    }, []);

    const getPosts = () => {
        axios.get(`https://mediaroom.jbs.com.br/getposts.php`, {
            params: {
                bracket: 2,
                slice: "Home",
                fullurl: '',
                nolinks: '',
                bullet: '',
                iframe: '',
            }
        }).then(res => {
            setPosts([
                {
                    tags: ['Identifique-se']
                }
            ])
            setIntro(res.data.intro.length > 0 ? res.data.intro[0] : null);
        });
    }

    return (
        <MainTemplate posts={posts} intro={intro} />
    );
}

export default Home;