import axios from "axios";
import React, { useEffect, useState } from "react";
import MainTemplate from "../../templates/Main";
import { useParams } from "react-router-dom";

const Base = () => {

    let { slice, card } = useParams();
    const [posts, setPosts] = useState([]);
    const [intro, setIntro] = useState(null);

    useEffect(() => {
        getPosts();
    }, []);

    const getPosts = () => {
        console.log('slice', slice);
        console.log('card', card);
        
        let params = {
            bracket: 2,
            fullurl: '',
            nolinks: '',
            bullet: '',
            iframe: '',
        };

        if(slice !== undefined){
            params.slice = slice;
        }
        if(card !== undefined){
            params.card = card;
        }

        axios.get(`https://mediaroom.jbs.com.br/getposts.php`, {
            params
        }).then(res => {
            console.log('base', res.data);
            setIntro(res.data.intro.length > 0 ? res.data.intro[0] : null);
            setPosts(res.data.posts);
        });
    }

    return (
        <MainTemplate posts={posts} intro={intro} />
    );
}

export default Base;