import React, { useEffect, useState } from "react";
import Description from "../Description";
import Title from "../Title";

const FormIntro = ({intro, name}) => {    
    const [filteredIntro, setFilteredIntro] = useState(null);

    useEffect(() => {
        getFilteredIntro();
    }, [intro, name]);

    const getFilteredIntro = () => {
        let toFilteredIntro = {...intro, title: intro.title.replace('[primeiro_nome]', name)};
        setFilteredIntro(toFilteredIntro);
    }

    return (
        <div className="container">
            {filteredIntro &&
                <div>
                    <Title post={filteredIntro} />
                    <Description post={filteredIntro} />
                </div>
            }
        </div>
    )
}

export default FormIntro;