import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Intro from "../../components/Intro";
import Faq from "../../components/Faq";
import Text from "../../components/Text";
import Identify from "../../components/Identify";

export const MainTemplate = ({posts, intro}) => {
    return (
        <>
            <Header />
                <div id="wrapper">
                    {intro &&
                        <section>
                            <Intro intro={intro} />
                        </section>
                    }
                    {posts.map((post, index) => (
                        <section key={index}>
                            {post.tags.includes('FAQ') &&
                                <Faq post={post} />
                            }
                            {post.tags.includes('Texto') &&
                                <Text post={post} />
                            }
                            {post.tags.includes('Identifique-se') &&
                                <Identify posts={posts} />
                            }
                        </section>
                    ))}
                </div>
            <Footer />
        </>
    );
}

export default MainTemplate;