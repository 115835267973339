export const replaceWithBrs = text => {
    let res = text.replaceAll('[#]', `<br />`);
    return res;
}

export const convertToBg = post => {
    if(post.meta.includes('GreenBG')){
        return 'greenbg';
    }
    if(post.meta.includes('WhiteBG')){
        return 'whitebg';
    }
    if(post.meta.includes('DarkBG')){
        return 'darkbg';
    }
    return 'nonebg';
}

export const stringToVar = str => {
    str = str.replace(/^\s+|\s+$/g, ''); // trim
    str = str.toLowerCase();
  
    // remove accents, swap ñ for n, etc
    var from = "ãàáäâèéëêìíïîòóöôùúüûñç·/_,:;";
    var to   = "aaaaaeeeeiiiioooouuuunc------";
    for (var i=0, l=from.length ; i<l ; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
        .replace(/\s+/g, '-') // collapse whitespace and replace by -
        .replace(/-+/g, '-'); // collapse dashes
    
    let strArr = str.split('-');
    let res = '';

    strArr.map((s, index) => {
        if(index === 0){
            res += s;
            return;
        }

        res += s.charAt(0).toUpperCase() + s.slice(1);
    });

    return res;
}

export const intToZip = value => {
    let res = '';
    while(value.replace('-', '').length < 8){
        value = '0' + value;
    }
    [...value].map((v, index) => {
        if(index === 4){
          res = res + v + '-';
        } else if(index < 8) {
          res = res + v;
        }
    });
    return res;
}

export const zipToInt = value => {
    let res = value;
    if(value.constructor === String){
        res = value.replace(/\D/g, "");
        res = parseInt(res);
        res = res ? res.toString() : '';
    }
    return res;
}

export const cleanPhone = value => {
    let res = value;
    if(value.constructor === String){
        res = value.replace(/\D/g, "");
    }
    return res;
}

export const cleanCPF = value => {
    let res = value;
    if(value.constructor === String){
        res = value.replace(/\D/g, "");
    }
    return res;
}