import React, { useEffect, useRef } from 'react';

const Image = ({post, type, className, position}) => {
    const el = useRef();
    const index = position ?? 0;

    useEffect(() => {
        setStyle();    
    }, []);

    const setStyle = () => {
        if(post.flagshipcaption == '[bottom]'){
            el.current.setAttribute('style', 'object-position: bottom');
            return;
        }
        if(post.flagshipcaption == '[center]'){
            el.current.setAttribute('style', 'object-position: center');
            return;
        }
        if(post.flagshipcaption == '[top]'){
            el.current.setAttribute('style', 'object-position: top');
            return;
        }
        el.current.setAttribute('style', getStyle());
    }

    const getFirstPic = () => {
        if(post.pics){
            if(post.pics.length > 0){
                return type === 'h' ? post.pics[index].pic_h : post.pics[index].pic_v;
            }
        }
    }

    const getCaption = () => {
        if(post.pics){
            if(post.pics.length > 0){
                return post.pics[index].caption;
            }
        }
    }

    const getStyle = () => {
        if(post.pics){
            if(post.pics.length > 0){
                let style = post.pics[index].style;
                if(style){
                    return style;
                }
            }
        }

        return '';
    }

    return (
        <img  ref={el} src={`${getFirstPic()}`} alt={getCaption()} className={className} />
    );
}

export default Image;