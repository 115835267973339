import React from "react";
import { replaceWithBrs } from "../../helpers/conversions";
import { getFont } from "../../helpers/params";

const Description = ({post}) => {
    return (
        <p
        style={{
            fontSize: `${post.descfontsize}px`,
            color: `#${post.desccolor}`,
            textAlign: post.descjustify,
            fontFamily: getFont(post.descfontfamily)
        }}
            dangerouslySetInnerHTML={{__html: replaceWithBrs(post.description) }}
        />
    )
}

export default Description;