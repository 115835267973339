import React, { useEffect, useState } from "react";
import { convertToCpf, convertToInt } from "../../helpers/masks";

const Identify = ({posts}) => {
    const [cpf, setCpf] = useState('');
    const [year, setYear] = useState('');

    const handleSubmit = e => {
        e.preventDefault();
        const validation = validate();
        if(!validation) return;

        const cpfCleared = cpf.replace(/\D/g, "");
        const yearCleared = year.replace(/\D/g, "");

        window.checkCPF(cpfCleared, yearCleared, 0, function teste(i, callback){
            handleData(JSON.parse(callback.responseText));
        });
    }

    const handleData = data => {
        if(data.length === 0){
            window.location.href=`/Home/Erropage`
        } else {
            const cpfCleared = cpf.replace(/\D/g, "");
            const yearCleared = year.replace(/\D/g, "");

            window.location.href=`/form?cpf=${cpfCleared}&ano_nascimento=${yearCleared}`;
        }
    }

    const validate = () => {
        if(cpf.length < 14){
            alert('Por favor, digite o CPF corretamente');
            return false;
        }

        return true;
    }

    return (
        <div className="container container-identify">
            <div className="identify">
                <p>Identifique-se:</p>
                <form onSubmit={handleSubmit}>
                    <label>
                    CPF:
                    <input 
                        style={{ width: 160 }}
                        type="text"
                        placeholder="000.000.000-00"
                        value={cpf}
                        onChange={e => setCpf( convertToCpf(e.target.value) )}
                    />
                    </label>
                    <label>
                    ANO DE NASCIMENTO:
                    <input 
                        style={{ width: 100 }}
                        type="text"
                        value={year}
                        placeholder={`AAAA`}
                        onChange={e => setYear( e.target.value.slice(0,4) )}
                    />
                    </label>
                    <button type="submit">Enviar</button>
                </form>
            </div>
        </div>
    )
}

export default Identify;