export const fonts = [
    'Open Sans',
    'Open Sans Bold',
    'IBM Plex Sans'
];

export const getFont = position => {
    if(fonts[position] !== undefined){
        return fonts[position];
    }

    return fonts[0];
}