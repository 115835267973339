import React from "react";
import Faq from "react-faq-component";
import Title from "../Title";

const styles = {
    bgColor: 'transparent',
    arrowColor: '#FFF'
};

const FaqComponent = ({post}) => {
    return (
        <div className="container">
            <Faq
                data={{
                    rows: [
                        {
                            title: <Title post={post} />,
                            content: <p dangerouslySetInnerHTML={{__html: post.contents }} />,
                        }]
                }}
                styles={styles}
            />
        </div>
    )
}

export default FaqComponent;